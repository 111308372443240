<h1 class="mat-align-left tkg-title" mat-dialog-title>Tarefas</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="tkg-row">
      <div class="tkg-col-40">
        <mat-form-field appearance="outline" class="tkg-select-100">
          <mat-label>Escolha uma Tarefa</mat-label>
          <mat-select formControlName="name">
            <mat-option *ngFor="let item of available_tasks" [value]="item.resource?.name">
              <ng-container>
                {{ item.resource?.name }}
                <br>
                <span style="font-size: 14px; white-space: normal; display: block; color: gray;">
                  - {{ item.resource?.observation }}
                </span>
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="tkg-col-40">
        <mat-form-field appearance="outline" class="tkg-select-100">
          <mat-label>Associar a uma Doca?</mat-label>
          <mat-select formControlName="dock_id">
            <mat-option
              *ngFor="let item of available_docks"
              [value]="item.id"
            >
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="tkg-col-40-flex">
        <mat-slide-toggle formControlName="notify_all"
          >Notificar todos manobristas?</mat-slide-toggle
        >
        <mat-form-field
          *ngIf="!form.get('notify_all')?.value"
          appearance="outline"
          class="tkg-select-100"
        >
          <mat-label>Escolha um Manobrista</mat-label>
          <mat-select formControlName="driver_id">
            <mat-option *ngFor="let item of drivers" [value]="item.driver_id">
              {{ item.driver_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end" class="tkg-dialog-btns">
  <button mat-raised-button type="button" color="secondary" (click)="cancel()">
    Fechar
  </button>
  <button
    cdkFocusInitial
    mat-raised-button
    type="submit"
    color="primary"
    style="color: white"
    (click)="submit()"
    [disabled]="!form.valid"
  >
    Salvar
  </button>
</div>

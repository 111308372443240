import { Component, Inject, OnInit } from "@angular/core";
import { DriversService } from "../shared/drivers.service";
import { UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogAlertComponent } from "../../shared/dialog-alert/dialog-alert.component";
// import { PermissionService } from "../../permission/shared/permission.service";
import { Carrier } from "../../carrier/carrier";
import { CarrierService } from "../../carrier/shared/carrier.service";
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  filter,
  tap,
  takeUntil,
  concatMap,
} from "rxjs/operators";
import { Subject } from "rxjs";
import { MaskUtil } from "../../shared/util/mask.util";
import { TranslateService } from "@ngx-translate/core";
import { BusinessPartnerTypeService } from "src/app/account/shared/business-partner-type.service";
import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";


@Component({
  selector: "app-drivers-edit",
  templateUrl: "./drivers-edit.component.html",
  styleUrls: ["./drivers-edit.component.scss"],
})
export class DriversEditComponent implements OnInit {
  showSMS: boolean = false;
  showEditPass: boolean = true;
  showInputPass: boolean = false;
  form = this.formBuilder.group({
    id: [this.data.item.id || null],
    name: [this.data.item.name],
    document: [this.data.item.resource.cpf],
    active: [this.data.item.active || true, [Validators.required]],
    carrier_id: [this.data.item.carrier_id || null],
    carrier_name: [this.data.item.carrier_name || null],
    is_valet: [this.data.item.is_valet || false],
    defaultValet: [this.data.item.default_valet || false],
    // whatsapp: [this.data.item.whatsapp || false, [Validators.required]],
    resource: this.formBuilder.group({
      name: [this.data.item.resource.name],
      carrier_id: [this.data.item.carrier_id],
      valet: [this.data.item.resource.valet || false],
      cnh: [this.data.item.resource.cnh, [Validators.required]],
      cpf: [this.data.item.resource.cpf, [Validators.required]],
      phone: [this.data.item.resource.phone, [Validators.required]],
      photo: [this.data.item.resource.photo],
      photoPerfil: [this.data.item.resource.photoPerfil],
      password: [this.data.item.resource.password],
      oneSignalUserID: [this.data.item.resource.oneSignalUserID],
      changePass: [false],
      sms: [this.data.item.resource.sms || false],
      //Propriedade default_carrier da tabela driver_client, É removido da resource na API e utilizado na criação/edição de driver_clients
      default_carrier: [this.data.item.default_carrier]
    }),
  });
  error: boolean = false;
  errorLabel: string;
  errors: any = {};
  maskUtil = MaskUtil;
  carriers: any = [];
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  searching: boolean = false;
  isCarrier: boolean = false;
  account: any = "[]";
  carrierId: number;
  protected _onDestroy = new Subject<void>();
  translateSource: any;
  status = ["activated", "deactivated"];
  canCreateValet: boolean = false;
  protected mode?: "driverAlreadyExists" | undefined;
  partnerTypeId: any;

  constructor(
    public dialogRef: MatDialogRef<DriversEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private driversService: DriversService,
    private carrierService: CarrierService,
    // private permissionService: PermissionService,
    private translate: TranslateService,
    private businessPartnerTypeService: BusinessPartnerTypeService,
    private BusinessPartnerService: BusinessPartnerService
  ) {
    this.account = JSON.parse(localStorage.getItem("account") || "[]");
    if ((this.account.system_client.resource.environment.allow_valet) ?? false) {
      this.canCreateValet = true;
    }

    this.isCarrier = this.account.is_carrier;
    this.carrierId = this.account.carrier_id;

    translate.get("Driver.driversEdit").subscribe((res: string) => {
      this.translateSource = res;
    });

    if (this.account.system_client.resource.environment.use_sms === true) {
      this.showSMS = true;
    }
  }

  onSubmit() {
    const formValue = this.form.getRawValue();
    formValue.is_valet = Boolean(formValue.is_valet);

    if(!formValue.is_valet)
      formValue.defaultValet = undefined;

    formValue.resource.phone = formValue.resource.phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");

    let driver = formValue;

    driver["resource"]["status"] = driver.active ? "activated" : "deactivated";
    if (this.data.action === "EDIT") {
      this.driversService.update(formValue, this.data.item.id).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: (err) => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: {
                title: err.error.title ? err.error.title : err.statusText,
                message: err.error.message,
              },
            });
          }
        }
      });
    } else if (this.data.action === "ADD") {
      formValue.document = formValue.resource.cpf;
      this.driversService.create(formValue).subscribe(
        {
          next: (data: any) => {
          if (data.status == "created_with_default_carrier") {
            this.dialog
              .open(DialogAlertComponent, {
                data: {
                  title: data.title ? data.title : data.statusText,
                  message: data.message,
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialogRef.close(this.data);
              });
          }
          if (data.error) {
            this.error = true;
            this.errorLabel = data.message;
            this.error = false;
          } else {
            this.dialogRef.close(data);
          }
        },
        error: (err) => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: {
                title: err.error.title ? err.error.title : err.statusText,
                message: err.error.message,
              },
            });
          }
        }
      }
      );
    }
  }

  setPass(value: boolean) {
    this.showInputPass = value;
  }

  ngOnInit() {
    // Se for adição de usuário, mostra input de senha e desativa check box
    // Caso edição ativa checkbox e desativa input de senha
    if (!this.data.item.id) {
      this.showEditPass = false;
      this.showInputPass = true;
    }    

    //Mostra as transportadoras, se for usuário de transportadora mostra apenas as transportadoras cadastradas
    this.carrierFilter.valueChanges
    .pipe(
      filter((search) => !!search),
      tap(() => (this.searching = true)),
      takeUntil(this._onDestroy),
      startWith({}),
      debounceTime(500),
      switchMap(() => {
        const options = {
          pageSize: 1000,
        };

        if (this.carrierFilter.value) {
          Object.assign(options, {
            search: `${this.carrierFilter.value}`,
          });
        }

        if (this.account.system_client.resource.is_business_partner) {
          return this.businessPartnerTypeService.all().pipe(
            concatMap((res) => {
              this.partnerTypeId = res.data.find((item: any) => item.ref_id === 'partner_carrier_id');
              return this.BusinessPartnerService.all(this.partnerTypeId);
            })
          );
        } else {
          // Retorne o Observable diretamente do service
          return this.carrierService.index(options);
        }
      }),
      map((response: any) => response.data)
    )
    .subscribe((data) => (this.carriers = data));

    if(this.data.action === "ADD"){
      this.mode = "driverAlreadyExists";
      this.form.get("resource.cpf")?.valueChanges
      .pipe(
        filter(()=>this.data.action === "ADD"),
        debounceTime(800),
        filter((value)=> (value && value.replace(/[^\d]/g,'').length === 11)),
        switchMap((value:string)=>{
          this.mode = "driverAlreadyExists";
          return this.driversService.showViaCpf({cpf:value})
        })
      )
      .subscribe(this.controlDriverRegister.bind(this));
    }
  }

  clearFilter() {
    this.form.value.resource.carrier_id = null;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  showStatus(item: any) {
    return this.translateSource.status[item];
  }

  close() {
    this.dialogRef.close();
  }

  private controlDriverRegister(driver?:any){
    this.mode = undefined;
    if(driver?.has_row){
      driver.resource.carrier_id = null;
      driver.resource.default_carrier = null;
      this.form.patchValue(driver, {emitEvent:false});
      [
        "resource.name",
        "resource.cnh",
        "resource.phone",
        "resource.changePass",
        "resource.password"
      ].forEach((field:string)=>{
        this.form.get(field)?.disable();
      });
    }else{
      const cpf = this.form.get('resource.cpf')?.value;
      this.form.reset({emitEvent:false});
      this.form.enable({emitEvent:false});
      this.form.get("resource.cpf")?.setValue(cpf,{emitEvent:false});
    }
  }
}

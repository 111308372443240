<h2>{{data.title}}</h2>
<div class="checklist-header">{{this.headerString}}</div>
<form [formGroup]="form">
        <mat-dialog-content>
          <form [formGroup]="form">
            <mat-vertical-stepper linear>
              <mat-step *ngFor="let step of steps; let i = index" [label]="'Passo ' + (i + 1)">

                <formly-form
                  [model]="model"
                  [fields]="getFieldsForStep(step)"
                  [options]="options"
                  [form]="form"
                ></formly-form>
                <div>
                  <button mat-button matStepperNext *ngIf="!isLastStep(step)">Próximo</button>
                  <button mat-button matStepperPrevious *ngIf="!isFirstStep(step)">Anterior</button>
                </div>
              </mat-step>
            </mat-vertical-stepper>
          </form>
        </mat-dialog-content>

        <mat-dialog-actions  align="end">
            <ng-container *ngIf="loading; else elseTemplate">
              <button mat-raised-button color="warn" disabled="disabled">
                {{
                  "Messages.saving" | translate: { Default: "Salvando ..." }
                }}
              </button>
            </ng-container>
            <ng-template #elseTemplate>
              <button type="button" mat-raised-button mat-dialog-close="true">
                {{
                  "Business_model.close" | translate: { Default: "Fechar" }
                }}
              </button>
              <button
                class="greenBtn"
                (click)="storeCheckList(); this.loading = true"
                mat-raised-button
                color="primary"
              >
                {{ "Business_model.save" | translate: { Default: "Salvar" } }}
              </button>
            </ng-template>
          </mat-dialog-actions>
</form>
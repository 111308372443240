import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AccountModule } from "./account/account.module";
import { CoreModule } from "./core/core.module";
import { AppComponent } from "./app.component";
// import { AgmCoreModule } from "@agm/core";
//import { ErrorNotFoundComponent } from './error-not-found/error-not-found.component';

import { UserModule } from "./user/user.module";

// import { WebcamModule } from 'ngx-webcam';
//import { DashboardEurofarmaModule } from "./dashboard-eurofarma/dashboard.module";
import { AgendamentoModule } from "./agendamento/agendamento.module";
import { MultiAgendamentoModule } from "./multiagendamento/multiagendamento.module";

import { ConfigurationsModule } from "./configurations/configurations.module";
import { DashboardModule } from "./dashboard/dashboard.module";
// import { PermissionModule } from "./permission/permission.module";
import { SharedModule } from "./shared/shared.module";
import { CarrierModule } from "./carrier/carrier.module";
//import { StatusModule } from "./status/status.module";

import { CommentModule } from "./comment/comment.module";
import { DriversModule } from "./drivers/drivers.module";

// import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

import { CheckinModule } from "./checkin/checkin.module";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatTooltipModule } from "@angular/material/tooltip";

import { DocumentsModule } from "./documents/documents.module";
import { ReportListModule } from "./report/report-list.module";
// Loreal
import { DashboardLorealModule } from "./dashboard-loreal/dashboard.module";
import { CheckinLorealModule } from "./checkin-loreal/checkin.module";
//import { DespachoLorealModule } from "./despacho-loreal/despacho.module";
import { PainelLorealMotoristaModule } from "./painel-motorista-loreal/painelmotorista.module";
import { CartModule } from "./cart/cart.module";

import { EventsModule } from "./events/events.module";

import { SchedulingModule } from "./scheduling/scheduling.module";
import { DashboardJandiraModule } from "./dashboard-jandira/dashboard-jandira.module";
import { NgxEchartsModule } from "ngx-echarts";
import { ReportDynamicListModule } from "./report-dynamic/report-dynamic-list.module";
import { DashboardV1Module } from "./dashboard-v1/dashboard-v1.module";
import { DashboardDynamicModule } from "./dashboard-dynamic/dashboard-dynamic.module";
import { DockModule } from "./docks/dock.module";
import * as echarts from "echarts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CollectPointModule } from "./collect-points/collect-points.module";
import { MicroServicesModule } from "./micro-services/micro-services.module";
import { HubModule } from "./hub/hub.module";
import { HealthCheckModule } from "./health-check/health-check.module";
import { AppRoutes } from "./app-routing.module";
import { MatIconModule } from "@angular/material/icon";
import { RegistrationsModule } from "./registrations/registrations.module";
import { GenericViewModule } from "./generic-view/generic-view.module";
import { AuthModule } from "./auth/auth.module";
import { LookerModule } from "./looker/looker.module";
import { TimelinesModule } from "./timelines/timelines.module";

@NgModule({
  declarations: [
    AppComponent,
    //ErrorNotFoundComponent
  ],
  imports: [
    RouterModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    CoreModule,
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyChgd4N3nP6TbzInsH85xTUPezFI0zeXVY",
    // }),
    SharedModule,
    AccountModule,
    AgendamentoModule,
    CheckinLorealModule,
    DashboardModule,
    ConfigurationsModule,
    DashboardLorealModule,
    UserModule,
    // PermissionModule,
    CarrierModule,
    // DespachoLorealModule,
    MultiAgendamentoModule,
    //StatusModule,

    CommentModule,
    DriversModule,
    PainelLorealMotoristaModule,
    CartModule,
    CheckinModule,
    // NgxMatSelectSearchModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    // NgxMaskModule.forRoot(),
    DocumentsModule,
    ReportListModule,
    EventsModule,
    SchedulingModule,
    DashboardJandiraModule,
    ReportDynamicListModule,
    // WebcamModule,
    DashboardV1Module,
    DashboardDynamicModule,
    DockModule,
    ReactiveFormsModule,
    FormsModule,
    CollectPointModule,
    MicroServicesModule,
    HubModule,
    HealthCheckModule,
    LookerModule,
    AppRoutes,
    MatIconModule,
    RegistrationsModule,
    GenericViewModule,
    AuthModule,
    TimelinesModule,
  ],
  providers: [],
  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

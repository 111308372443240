import { ChecklistService } from './../checklist.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { format } from "date-fns";
import { TranslateService } from '@ngx-translate/core';
import { SupportDataService } from '../support-data.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-checklist-dynamic-form',
  templateUrl: './checklist-dynamic-form.component.html',
  styleUrls: ['./checklist-dynamic-form.component.scss']
})
export class ChecklistDynamicFormComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;
  form = new UntypedFormGroup({
    emails: new UntypedFormControl([]),
  });
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  loading = false;
  validationMsgs: any[] = [];
  config_scheduling: any;
  account: any = {};
  operation: string = '';
  dataVehicleType: any[];
  aditionalFields: any = {};
  scaleData: any = [];
  steps: any[] = [];
  showHeader: boolean = false;
  headerString: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private translate: TranslateService,
    public checklistServide: ChecklistService,
    public dialogRef: MatDialogRef<ChecklistDynamicFormComponent>,
    private supportDataService: SupportDataService,
  ) {}

  async ngOnInit() {
    this.scaleData = this.data.formItens;
    try {
      this.headerString = await this.getHeaderData();
      this.fields = await this.setFormFields();

    } catch (error) {
      console.error("Erro ao inicializar o componente:", error);
    }

    this.account = JSON.parse(localStorage.getItem("account") || "{}");
      this.config_scheduling =
        this.account?.system_client?.resource?.environment?.config_scheduling;
  }

  async getHeaderData(): Promise<string> {
    const data = { name: 'checklist_header', id: this.data.item.event.id };
  try {
    const header = await lastValueFrom(this.checklistServide.getChecklistHeader(data));
    return header.data || '';
  } catch (error) {
    console.error("Erro ao buscar dados do cabeçalho:", error);
    throw error;
  }
  }
    
  async setFormFields(): Promise<FormlyFieldConfig[]> {
    try {
      const response = await lastValueFrom(this.supportDataService.generic({ name: this.data.form }));
      this.fields = response.data.resource;
      this.createSteps(this.fields);
      this.setDataIntoChecklist(this.fields);
      return this.fields || [];
    } catch (error) {
      console.error("Erro ao buscar campos do formulário:", error);
      throw error;
    }
  }

  setDataIntoChecklist(fields: any) {
    if(!fields || !this.scaleData || this.scaleData.length === 0) {
      return;
    }
    
    const apiData = this.scaleData[0]; 
    this.fields.forEach(field => {
      const fieldKey = field.key;
      if (typeof fieldKey === 'string' || typeof fieldKey === 'number') {
        if (apiData[fieldKey] !== undefined) {
          if (field.type === 'select') {
            if (field.props && Array.isArray(apiData[fieldKey])) {
              field.props.options = apiData[fieldKey].map((option: any) => ({
                id: option,
                name: option
              }));
            }
          } else {
            this.model[fieldKey] = apiData[fieldKey];
          }
        }
      }
    });
  }  

  storeCheckList($type?: string) {
    const formData = this.adjustFormData({ ...this.form.value });

    console.log(formData)

    const time_scale = this.form.get("time_scale");
    const date_scale = this.form.get("date_scale");

    const data = {
      ...this.aditionalFields,
      ...formData,
      ...time_scale
    };

    if(time_scale !== null) {
      if (time_scale?.value && date_scale?.value) {
        // Concatena o dia escolhido no datepicker com o horário escolhido
        // a partir dos slots de horário
        let date = format(new Date(date_scale.value), "yyyy-MM-dd");
        // Divide a string time_scale em duas partes, gravando na variável o segundo elemento (horário)
        let time = time_scale.value.split("T")[1];
        // Concatena date e hora
        data.time_scale = `${date}T${time}`;
      }
    }

    if (this.form.status === "VALID") {
      if ($type && $type == "edit") {
        data["action"] = "edit";
        data["id"] = this.data.schedule.id;

        this.checklistServide
          .updateChecklist(data)
          .subscribe((r: any) => {
            if (r.error) {
              this.loading = false;
              this.validationMsgs = r.msg;
              //Se mensagem retornar com status new_code_generated, fechar com o status e mensagem como parametros
              //Para que seja aberto o modal informando o usuário que foi utilizado um código diferente do informado
            } else if (r.status == "new_code_generated") {
              this.close({ status: r.status, message: r.msg });
            } else {
              this.close({
                message: this.translate.instant("Messages.edited_schedule_2", {
                  Default: "Checklist editado com sucesso!",
                }),
              });
            }
          });
      } else {
          this.checklistServide
            .createChecklis(data)
            .subscribe((r: any) => {
              if (r.error) {
                this.loading = false;
                this.validationMsgs = r.msg;
                //Se mensagem retornar com status new_code_generated, fechar com o status e mensagem como parametros
                //Para que seja aberto o modal informando o usuário que foi utilizado um código diferente do informado
              } else if (r.status == "new_code_generated") {
                this.close({ status: r.status, message: r.msg });
              } else {
                this.close({
                  message: this.translate.instant(
                    "Messages.created_schedule_2",
                    {
                      Default: "Checklist criado com sucesso!",
                    },
                  ),
                });
              }
        });
      }
    }
  }

  adjustFormData(form: any) {
    let newFormData: any = {};
    const keys = Object.keys(form);
    keys.forEach((item: any) => {
      if (Array.isArray(form[item])) {
        newFormData[item] = form[item];
      } else if (typeof form[item] === "object" && form[item] != null) {
        newFormData[item] = form[item].id;
      } else {
        newFormData[item] = form[item];
      }
    });

    return newFormData;
  }

  close(message?: any) {
    this.dialogRef.close(message);
  }

  createSteps(data: any): any {
    const steps: any[] = [];
    let currentStep: any = { fields: [] };
  
    data.forEach((item: any) => {
      if (item.key === 'header') {
        if (currentStep.fields.length) {
          steps.push(currentStep);
        }
        currentStep = { title: item.label, fields: [] };
      } else {
        currentStep.fields.push(item);
      }
    });
    
    if (currentStep.fields.length) {
      steps.push(currentStep);
    }
  
    this.steps = steps;
    return steps;
  }


  getFieldsForStep(step: any) {
    return step.fields;
  }

  isLastStep(step: any) {
    return this.steps.indexOf(step) === this.steps.length - 1;
  }

  isFirstStep(step: any) {
    return this.steps.indexOf(step) === 0;
  }

}

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SignaturePadComponent } from '../../signature-pad/signature-pad.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-formly-signature-pad',
  templateUrl: './formly-signature-pad.component.html',
  styleUrls: ['./formly-signature-pad.component.scss']
})
export class FormlySignaturePadComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  imgBase64: string = "";
  _formControl = new FormControl();

  @ViewChild('signatureCanvas') canvasEl!: ElementRef;

  constructor(private dialog: MatDialog) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.imgBase64) {
      this.drawImage(this.imgBase64);
    }
  }

  openSignaturePad() {
    const dialogRef = this.dialog.open(SignaturePadComponent, {
      data: {
        img: this.imgBase64 || ''
      },
    });
  
    dialogRef.afterClosed().subscribe((img: string | undefined) => {
      if (img === '') {
        this.imgBase64 = '';
        this.formControl.setValue('');
        this.clearCanvas();
      } else if (img) {
        this.imgBase64 = img;
        this.formControl.setValue(this.imgBase64);
        this.drawImage(this.imgBase64);
      }
    });
  }
  
  private clearCanvas() {
    const canvas = this.canvasEl.nativeElement as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    context?.clearRect(0, 0, canvas.width, canvas.height);
  }
  

  private drawImage(base64Image: string) {
    const canvas = this.canvasEl.nativeElement as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    const image = new Image();
    image.src = base64Image;
    image.onload = () => {
      this.resizeCanvas();
      context?.clearRect(0, 0, canvas.width, canvas.height); // Limpa o canvas antes de desenhar
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
  }

  private resizeCanvas() {
    const canvas = this.canvasEl.nativeElement as HTMLCanvasElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d')?.scale(ratio, ratio);
  }
}

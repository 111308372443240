import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';

@Component({
  standalone: true,
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  imports: [MatButtonModule]
})
export class SignaturePadComponent implements AfterViewInit {
  _formControl = new FormControl();
  signatureNeeded: boolean = true;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;

  constructor(
    private dialogRef: MatDialogRef<SignaturePadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit(): void {
    const canvas = this.canvasEl.nativeElement;

    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: '#FFFFFF',
      penColor: 'black'
    });

    if (this.data.img) {
      this.loadImage(this.data.img);
      this.signatureImg = this.data.img;
    }

    this.resizeCanvas();

    // Ao fechar o diálogo, envie a imagem existente se não houver nova assinatura
    this.dialogRef.beforeClosed().subscribe(() => {
      if (this.signaturePad.isEmpty() && this.signatureImg) {
        this.dialogRef.close(this.signatureImg);
      }
    });
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  private resizeCanvas() {
    const canvas = this.canvasEl.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d')?.scale(ratio, ratio);
  }

  private loadImage(base64Image: string) {
    const canvas = this.canvasEl.nativeElement;
    const context = canvas.getContext('2d');
    const image = new Image();
    image.src = base64Image;
    image.onload = () => {
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
      this.signaturePad.fromDataURL(base64Image);
    };
  }

  savePad() {
    if (!this.signaturePad.isEmpty()) {
      const base64Data = this.signaturePad.toDataURL();
      this.signatureImg = base64Data;
    } else {
      this.signatureImg = '';
    }
    this._formControl.setValue(this.signatureImg);
    this.dialogRef.close(this.signatureImg);
  }

  clearPad() {
    this.signaturePad.clear();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, map, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class YardTaskService {
  constructor(private http: HttpClient) {}

  getAvailableTasks(options?: any): Observable<any> {
    const url = `${environment.apiUrl}/yard-tasks`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/yard-tasks`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  get(id: string, options?: any): Observable<any> {
    const url = `${environment.apiUrl}/yard-tasks/${id}`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  update(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/yard-tasks`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  delete(id: string): Observable<any> {
    const url = `${environment.apiUrl}/yard-tasks/${id}`;

    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }
}

import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent {
  arrayMinute: string[] = 
  [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
    '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
    '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
    '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
  ];
  arrayHour: string[] = [
    '00', '01', '02', '03', '04', 
    '05', '06', '07', '08', '09',
    '10', '11', '12', '13', '14', 
    '15', '16', '17', '18', '19',
    '20', '21', '22', '23'
  ];

  selectedDate: Date | null = null;
  selectedDay: string = '';
  selectedTime: string = '';
  selectedHour: string = '';
  selectedMinute: string = '';

  constructor(private dialogRef: MatDialogRef<DateTimePickerComponent>) {}

  @Output() dateTimeSelected = new EventEmitter<string>();

  emitAndClose(): void {
    if (this.selectedDay && this.selectedTime) {
      const dateTime = `${this.selectedDay} ${this.selectedTime}`;
      this.dateTimeSelected.emit(dateTime);
      this.dialogRef.close(dateTime);
    }
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }

  onDateChange(event: any): void {
    if (moment.isMoment(event)) { 
      this.selectedDate = event.toDate();
    } else if (event instanceof Date) {
      this.selectedDate = event;
    } else {
      return;
    }
    this.selectedDay = this.formatDate(this.selectedDate);
    console.log(this.selectedDay);
  }

  onTimeChange(hour: string, minute: string): void {
    this.selectedHour = hour;
    this.selectedMinute = minute;
    this.selectedTime = `${hour}:${minute}`;
  }

  private formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  isSelectedHour(hour: string): boolean {
    return this.selectedHour === hour;
  }

  isSelectedMinute(minute: string): boolean {
    return this.selectedMinute === minute;
  }
}

import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { DateTimePickerComponent } from '../../date-time-picker/date-time-picker.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-formly-date-time-picker',
  templateUrl: './formly-date-time-picker.component.html',
  styleUrls: ['./formly-date-time-picker.component.scss'],
})
export class FormlyDateTimePickerComponent extends FieldType {
  @ViewChild(MatDatepicker) picker: MatDatepicker<any>;

  _formControl = new FormControl;
  time: string = '';
  open: any;

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  protected openCalendarDialog() {
    const dialogRef = this.dialog.open(DateTimePickerComponent, {
      panelClass: ['dialog-custom'],
      width: '600px',
      height: '600px',
      data: {}, 
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.time = result
        this.cdr.detectChanges();
      }
    });
  }
}

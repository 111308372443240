import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericViewListComponent } from './generic-view-list/generic-view-list.component';
import { GenericViewMapComponent } from './generic-view-map/generic-view-map.component';
import { GenericViewDialogComponent } from './generic-view-dialog/generic-view-dialog.component';
import { GenericViewRoutingModule } from './generic-view-routing.modules';
import { ShowLocationsMapComponent } from '../shared/show-locations-map/show-locations-map.component';
import { PaginateSortTableComponent } from '../shared/paginate-sort-table/paginate-sort-table.component';
import { LoadingTableComponent } from '../shared/loading-table/loading-table.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { GenericViewMapDialogComponent } from './generic-view-map-dialog/generic-view-map-dialog.component';



@NgModule({
  declarations: [
    GenericViewListComponent,
    GenericViewMapComponent,
    GenericViewDialogComponent,
    GenericViewMapDialogComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    GenericViewRoutingModule,
    ShowLocationsMapComponent,
    PaginateSortTableComponent,
    LoadingTableComponent
  ]
})
export class GenericViewModule { }

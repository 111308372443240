<h1 class="mat-align-left tkg-title" mat-dialog-title>Tarefas</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="tkg-row">
      <div class="tkg-col-40">
        <mat-form-field appearance="outline" class="tkg-select-100">
          <mat-label>Escolha uma Tarefa</mat-label>
          <mat-select formControlName="name">
            <mat-option *ngFor="let item of task_types" [value]="item.name">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="tkg-col-40">
        <mat-form-field class="tkg-input-100" appearance="outline">
          <mat-label>Observação</mat-label>
          <input matInput placeholder="Observação" formControlName="observation">
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end" class="tkg-dialog-btns">
  <button mat-raised-button type="button" color="secondary" (click)="cancel()">Fechar</button>
  <button cdkFocusInitial mat-raised-button type="submit" color="primary" style="color: white;" (click)="submit()" [disabled]="!form.valid">Salvar</button>
</div>

<mat-form-field appearance="outline">
  <mat-label>{{ to.label }}</mat-label>
  <input
    matInput
    [(ngModel)]="time"
    [disabled]="true"
  />
  <button
    mat-icon-button
    matSuffix
    aria-label="Abrir calendário"
    (click)="openCalendarDialog()"
  >
    <mat-icon>calendar_today</mat-icon>
  </button>
</mat-form-field>

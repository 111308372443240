<div class="date-time-picker">
  <div class="content">
    <mat-card class="calendar-container">
      <mat-calendar 
        [(selected)]="selectedDate" 
        (selectedChange)="onDateChange($event)">
      </mat-calendar>
    </mat-card>
    <div class="time-picker-container">
      <div class="time-list">
        <h3>Horas</h3>
        <div class="scrollable-list">
          <button 
            *ngFor="let hour of arrayHour" 
            (click)="onTimeChange(hour, selectedMinute || '00')"
            [ngClass]="{'selected': isSelectedHour(hour)}">
            {{ hour }}
          </button>
        </div>
      </div>
      <div class="time-list">
        <h3>Minutos</h3>
        <div class="scrollable-list">
          <button 
            *ngFor="let minute of arrayMinute" 
            (click)="onTimeChange(selectedHour || '00', minute)"
            [ngClass]="{'selected': isSelectedMinute(minute)}">
            {{ minute }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button mat-button color="warn" (click)="closeDialog()" class="small-button">Fechar</button>
    <button color="primary" mat-raised-button style="color: white" (click)="emitAndClose()" class="small-button">Enviar</button>
  </div>
</div>

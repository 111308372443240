<div class="signature-container">
  <canvas
    class="tkg-signature-pad"
    #canvas
    (touchstart)="startDrawing($event)"
    (touchmove)="moved($event)"
  ></canvas>
  <div class="button-container">
    <button type="button" mat-button color="warn" (click)="clearPad()">Apagar</button>
    <button type="button" mat-button color="primary" (click)="savePad()">Salvar</button>
  </div>
</div>

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  Map,
  Marker,
  Control,
  control,
  geoJSON,
  Icon,
  DivIcon,
  map,
  tileLayer,
  SidebarOptions,
  LeafletEvent,
} from "leaflet";
import "node_modules/leaflet-sidebar-v2/js/leaflet-sidebar.min.js";

@Component({
  selector: "app-generic-view-map-dialog",
  templateUrl: "./generic-view-map-dialog.component.html",
  styleUrls: ["./generic-view-map-dialog.component.scss"],
})
export class GenericViewMapDialogComponent implements OnInit {
  public map!: Map;

  constructor(
    public dialogRef: MatDialogRef<GenericViewMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    console.log("Data");
    console.log(this.data);
    this.initializeMap();
  }

  private initializeMap() {
    const osm = tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: "© OpenStreetMap",
    });

    const googleSatellite = tileLayer(
      "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
      {
        maxZoom: 19,
        attribution: "© Google",
      },
    );

    this.map = map("map", {
      center: [-23.199126571428568, -45.94579814285715],
      zoom: 15,
    });

    osm.addTo(this.map);

    // CAMADAS
    // 1 - Dispositivos
    // this.geoJSONpoints = geoJSON(this.geojsonPointMarkers, {
    //   // style: (feature: any) => {
    //   //   return feature.properties && feature.properties.style;
    //   // },
    //   pointToLayer: this.setPin,
    //   onEachFeature: this.onEachFeature,
    // })
    //   .on("click", this.markerOnClick, this)
    //   .addTo(this.map);

    // // 2 - Cercas
    // let mainFenceStyle = {
    //   color: "#868b8e",
    //   weight: 5,
    //   fill: false,
    //   opacity: 1,
    //   dashArray: "5, 10",
    // };

    // let secundaryFenceStyle = {
    //   color: "#b9b7bd",
    //   weight: 4,
    //   fill: false,
    //   opacity: 1,
    //   dashArray: "1,5",
    // };

    // const mainFence = geoJSON(this.geoJSONlayers, {
    //   filter: (feature: any) => {
    //     return feature.properties.type === "m";
    //   },
    //   style: mainFenceStyle,
    // }).addTo(this.map);

    // const secundaryFence = geoJSON(this.geoJSONlayers, {
    //   filter: (feature: any) => {
    //     return feature.properties.type === "s";
    //   },
    //   style: secundaryFenceStyle,
    // }).addTo(this.map);

    const baseMaps: any = {
      OpenStreetMap: osm,
      Satélite: googleSatellite,
    };

    // const overlayMaps: any = {
    //   Dispositivos: this.geoJSONpoints,
    //   "Cerca Principal": mainFence,
    //   "Cerca Secundária": secundaryFence,
    // };

    const layerControl = control.layers(baseMaps).addTo(this.map);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(
    private http: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  show(id: number): Observable<any> {
    const url = `${environment.apiUrl}/checklist/${id}`;

    return this.http.get<any>(url,).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/checklist`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  update(data: any) {
    const url = `${environment.apiUrl}/checklist`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  createChecklis(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/newchecklist`;
    const options = { params: params };
    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getChecklist(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newchecklist/${id}`;
    return this.http.get<any>(url,).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getAllChecklist(option: any): Observable<any> {
    const params = new HttpParams({ fromObject: option })
    const url = `${environment.apiUrl}/newchecklist`
    return this.http.get<any>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  updateChecklist(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/newchecklist`;
    const options = { params: params };
    return this.http.put<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deleteChecklist(id: string | number) {
    const url = `${environment.apiUrl}/newchecklis/${id}`;
    return this.http
      .delete(url, this.httpOptions)
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  getChecklistHeader(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/checklistheader`;
    const options = { params: params };
    return this.http.post(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  getScaleData(id: number): Observable<any> {
    const url = `${environment.apiUrl}/scaledata/${id}`;
    return this.http.get<any>(url,).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
}

<h1 mat-dialog-title>Timeline dos status da jornada</h1>
<mat-dialog-content class="mat-typography">
  <p><b>Operação</b> {{ data.data?.operation_name ?? "" }}</p>
  <p *ngIf="data.data.carrier_name.length > 0">
    <b>Transportadora</b> {{ data.data.carrier_name }}
  </p>
  <p *ngIf="data.data.driver_name.length > 0">
    <b>Motorista</b> {{ data.data.driver_name }}
  </p>
  <p *ngIf="data.data.device_linked.length > 0">
    <b>Dispositivo vinculado</b> {{ data.data.device_linked }}
  </p>
  <p *ngIf="data.data.board_horse.length > 0">
    <b>Placa cavalo</b> {{ data.data.board_horse }}
  </p>
  <p *ngIf="data.data.board_cart != null">
    <b>Placa carreta</b> {{ data.data.board_cart }}
  </p>
  <ngx-timeline
    [events]="events"
    [langCode]="langCode"
    [enableAnimation]="enableAnimation"
    [reverseOrder]="true"
    [changeSideInGroup]="changeSideInGroup"
  >
  </ngx-timeline>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
</mat-dialog-actions>

<h2 mat-dialog-title class="title">{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <div id="map"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    style="color: white"
    color="primary"
    [mat-dialog-close]="true"
  >
    Ok
  </button>
</mat-dialog-actions>

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GenericService } from '../../generic.service';
import { RegistrationService } from '../../registration.service';
import { SupportDataService } from '../../support-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { YardTaskService } from 'src/app/account/shared/yard-task.service';

@Component({
  selector: 'app-add-dialog-tasks',
  templateUrl: './add-dialog-tasks.component.html',
  styleUrls: ['./add-dialog-tasks.component.scss']
})
export class AddDialogTasksComponent implements OnInit {

  task_types: any = [];

  columnsSchema: any[] = [];
  transformData: any = {};
  transformMethods: any = {}
  displayedColumns: string[] = [];
  dataSource: any[] = [];
  task: any = {};

  form = this.formBuilder.group({
    name: ["", Validators.required],
    observation: [""]
  });

  display_message = false;
  message = '';
  user: any;
  showTable = false;

  constructor(
    private genericService: GenericService,
    private supportDataService: SupportDataService,
    private yardTaskService: YardTaskService,
    public formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getTaskTypesList();
    
    if(this.data?.item?.id) {
      this.getData();
    }
  }

  getData() {
    const options = {
      search: false,
      paginate: false,
      pageSize: 0,
      page: 1,
      orderBy: '',
      sortedBy: '',
    };
  
    this.yardTaskService.get(this.data?.item?.id, options).subscribe((r: any) => {  
      this.task = r.resource;
      if (r.resource) {
        this.form.patchValue({
          name: r.resource?.name,
          observation: r.resource?.observation
        });
      }
    });
  }

  submit() {
    const updatedResource = Object.assign({}, this.task, this.form.value);
    const data = {
      id: this.data?.item?.id,
      resource: updatedResource
    }

    if(this.data?.item?.id) {
      this.yardTaskService.update(data).subscribe((r: any) => {
        this.dialogRef.close();
      });
    } else {
      this.yardTaskService.create(data).subscribe((r: any) => {
        this.dialogRef.close();
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  getTaskTypesList() {
    this.supportDataService.generic({ name: 'task_types' }).subscribe((r: any) => {
      this.task_types = r.data.resource;
    });
  }
}

<div [formControl]="_formControl" class="container">
  <div class="header" disabled>
    <h3>{{ props.label || 'Assinatura' }}</h3>
    <button mat-icon-button (click)="openSignaturePad()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="tkg-container-control">
    <div class="item-list">
      <div class="item">
        <canvas #signatureCanvas class="signature-canvas"></canvas>
      </div>
    </div>
  </div>
</div>